import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './package.scss';
import guitarImg from '../../../img/guitar.svg';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import { formatPrice } from '../../../helpers/format-price.helper';
import MemoVideoPlayer from "../../Common/VideoPlayer/VideoPlayer";
import {HTMLContent} from "../../Common/Content";
import shoppingCartService from "../../../services/shopping-cart.service";
import {handleError, handleInternalServerError} from "../../../helpers/error-handler.helper";
import * as HttpStatus from "http-status-codes";
import NotificationService from "../../../services/notification.service";
import axiosService from "../../../services/axios.service";

const Package = ({ packageData, body }) => {
  const url = `${process.env.API_URL}/packages/${packageData.id}/purchase-availability`;
  const [isInCart, setIsInCart] = useState(false);
  const [isAddingToCartInProcess, setIsAddingToCartInProcess] = useState(false);

  async function getPurchaseActivity() {
    const params = shoppingCartService.hasToken() ? { token: shoppingCartService.getCartToken() } : {};

    return await axiosService.get(url, { params })
      .then(res => res.data.data)
      .catch((err) => handleInternalServerError(err));
  }

  useEffect(() => {
    getPurchaseActivity().then((data) => {
      if (data) {
        setIsInCart(data.isInCart);
      }
    });
  }, []);

  async function addPackageToCart() {
    await shoppingCartService.addPackageToCart(packageData.id)
      .then(() => {
        setIsInCart(true);
        NotificationService.info('The Package has been added to cart');
        shoppingCartService.updateCount();
      });
  }

  async function addToCart() {
    try {
      setIsAddingToCartInProcess(true);
      if (shoppingCartService.hasToken()) {
        await addPackageToCart();
      } else {
        const cartToken = await shoppingCartService.createCart();
        shoppingCartService.setCartToken(cartToken);
        await addPackageToCart();
      }
    } catch (err) {
      handleInternalServerError(err);
      handleError(err, [
        HttpStatus.UNPROCESSABLE_ENTITY,
        HttpStatus.BAD_REQUEST,
      ])
    } finally {
      setIsAddingToCartInProcess(false)
    }
  }

  function getAddToCartButtonText() {
    if (isInCart) {
      return 'Added to cart';
    }

    if (isAddingToCartInProcess) {
      return 'Adding...';
    }

    return 'Add to cart';
  }

  return (
    <section className="py-6 d-flex flex-column">
      <div className="d-flex flex-column flex-sm-row">
        <div className="hero-wrap mr-sm-5 mr-lg-9">
          <PreviewCompatibleImage
            imageInfo={{
              image: packageData.heroUrl || guitarImg,
              alt: packageData.title,
              className: 'hero-img',
            }}
          />
        </div>
        <div className="d-flex flex-column">
          <h1 className="h3 text-uppercase mt-0 mb-3 mb-lg-4">{packageData.title}</h1>
          {
            packageData.included && (
            <section className="d-flex flex-column ml-2">
                <h2 className="subtitle">What's included</h2>
                <div className="package-list-bullet caption" dangerouslySetInnerHTML={{ __html: packageData.included }} />
            </section>
            )
          }
          {
            packageData.price && (
              <React.Fragment>
                <p className="ml-2 mb-3 mb-lg-4 mb-xl-5 mt-auto">
                  <sup className="sup-price">$</sup>
                  <span className="price">{formatPrice(packageData.price)}</span>
                </p>
                <div className="mb-4 d-flex flex-lg-row flex-column align-items-start">
                  <a
                    href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=package&id=${packageData.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-tg btn-tg-sale btn-buy-package text-nowrap">
                    Buy Package
                  </a>
                  <div className="d-flex align-items-start flex-xl-row flex-column ml-lg-2 mt-2 mt-lg-0 btn-group-second">
                    <button
                      type='button'
                      className="btn-tg btn-buy-package btn-tg-secondary btn-tg-lg mt-auto btn-add-to-cart text-nowrap w-100"
                      disabled={isAddingToCartInProcess || isInCart}
                      onClick={addToCart}
                    >
                      {
                        isAddingToCartInProcess && (
                          <span
                            className="spinner-border spinner-border-sm text-capitalize"
                            role="status"
                            aria-hidden="true"
                          />
                        )
                      }
                      { getAddToCartButtonText() }
                    </button>
                    <a
                      href={`/gifts?type=package&id=${packageData.id}`}
                      className="btn-send-gift-top btn-buy-package ml-xl-2 ml-0 mt-xl-0 mt-2 btn-tg btn-tg-secondary btn-tg-lg text-nowrap w-100">
                      Send as Gift
                    </a>
                  </div>
                </div>
                <p className="caption">
                  Full Lifetime Access to this package
                </p>
              </React.Fragment>
            )
          }
        </div>

      </div>

      <hr className="divider my-5 my-lg-9"/>
      <div className="w-100 mb-5" id="videoWrap">
        <MemoVideoPlayer video={packageData.video}/>
      </div>
      { body && <div className="mb-5"><HTMLContent content={body}/></div> }
      <p className="mb-5" dangerouslySetInnerHTML={{ __html: packageData.description }} />

      <section>
        <h2 className="h3 text-uppercase mb-3 text-primary-600">Lessons</h2>
        <ul className="position-relative p-0">
          {
            packageData.lessons && packageData.lessons.map((lesson, i) => (
              <li key={i} className="list-unstyled border-divider lesson-item position-relative pb-3 mb-3">
                <h5 className="h6 font-weight-normal text-primary">Lesson {i +1}: {lesson.title}</h5>
                <p className="mb-0 description" dangerouslySetInnerHTML={{ __html: lesson.description }} />
              </li>
            ))
          }
        </ul>
      </section>
      <div className="mx-auto d-flex flex-wrap justify-content-center align-items-center mb-5 text-center btn-group-bottom">
        <a
          href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=package&id=${packageData.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-tg btn-tg-sale btn-buy-package">
          BUY THIS PACKAGE
        </a>
        <button
          type='button'
          className="btn-tg btn-buy-package btn-tg-secondary btn-tg-lg text-nowrap"
          disabled={isAddingToCartInProcess || isInCart}
          onClick={addToCart}
        >
          {
            isAddingToCartInProcess && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )
          }
          { getAddToCartButtonText() }
        </button>
        <a
          href={`/gifts?type=package&id=${packageData.id}`}
          className="btn-tg btn-buy-package btn-tg-secondary">
          Send as Gift
        </a>
      </div>
    </section>
  );
};

Package.propTypes = {
  packageData: PropTypes.object,
  body: PropTypes.string
};

export default Package

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Package from '../components/Packages/Package/Package';

const PackagePage = ({ data }) => {
  const  packageData = data.markdownRemark.frontmatter;
  const body = data.markdownRemark.html;

  return (
    <Layout title={packageData.title} description={packageData.description}>
      <div className="container">
        <div className="row">
          <div className="col">
            <Package packageData={packageData} body={body} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

PackagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PackagePage;

export const pageQuery = graphql`
  query PackageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        description
        heroUrl
        price
        included
        video {
          id
          title
          videoId
          platform
          url
          thumbnail
        }
        lessons {
          id
          title
          description
        }
      }
      html
    }
  }
`;
